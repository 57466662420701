<template>
  <v-row no-gutters class="flex-column">
    <v-card outlined>
      <v-card-text>
        <v-data-table
          :headers="table.headers"
          :items="allEvents"
          :loading="allEvents.length == 0"
        >
          <template v-slot:item.is_active="{ item }">
            <v-chip :color="item.is_active == 1 ? 'success' : 'error'">
              {{ item.is_active == 1 ? "Aktif" : "Tidak Aktif" }}
            </v-chip>
          </template>
          <template v-slot:item.image="{ item }">
            <v-btn icon @click="showImage(item.id)">
              <v-icon>mdi-image</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.action="{ item }">
            <v-menu left offset-x>
              <template v-slot:activator="{ attrs, on }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list nav dense dark>
                <v-tooltip left transition="slide-x-reverse-transition">
                  <template v-slot:activator="{ attrs, on }">
                    <v-list-item
                      v-bind="attrs"
                      v-on="on"
                      @click="editData(item)"
                    >
                      <v-icon>mdi-file-edit</v-icon>
                    </v-list-item>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                
                <v-tooltip left transition="slide-x-reverse-transition">
                  <template v-slot:activator="{ attrs, on }">
                    <v-list-item
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteConfirmation(item.id)"
                    >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-list-item>
                  </template>
                  <span>Hapus</span>
                </v-tooltip>

                <v-tooltip left transition="slide-x-reverse-transition">
                  <template v-slot:activator="{ attrs, on }">
                    <v-list-item v-bind="attrs" v-on="on" :to="`/invoices?event_id=${item.id}`">
                      <v-icon>mdi-receipt</v-icon>
                    </v-list-item>
                  </template>
                  <span>Resi</span>
                </v-tooltip>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- image show dialog -->
    <v-dialog v-model="image.show" max-width="800">
      <v-sheet v-if="image.src">
        <v-img contain :src="image.src">
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-btn icon @click="image.show = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-img>
      </v-sheet>
    </v-dialog>

    <!-- edit dialog -->
    <v-dialog width="800" v-model="edit.isEdit" persistent>
      <v-card dark>
        <v-toolbar flat>
          <v-btn
            color="accent"
            @click="saveData"
            :loading="edit.loading"
            :disabled="edit.loading"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="edit.isEdit = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="edit" v-if="edit.data">
            <v-row>
              <v-col>
                <v-text-field
                  label="Judul"
                  v-model="edit.data.title"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-autocomplete
                  label="Aktif"
                  v-model="edit.data.is_active"
                  :items="isActive"
                  item-text="text"
                  item-value="value"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-menu
                  :close-on-content-click="false"
                  v-model="edit.datepick.start"
                  :return-value.sync="edit.data.start_date"
                  ref="startDate"
                  offset-y
                  min-width="auto"
                  transition="slide-x-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Mulai"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      v-model="edit.data.start_date"
                    ></v-text-field>
                  </template>
                  <v-date-picker no-title v-model="edit.data.start_date">
                    <v-spacer></v-spacer>
                    <v-btn icon @click="saveDate('startDate')">
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                    <v-btn icon @click="edit.datepick.start = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  :close-on-content-click="false"
                  v-model="edit.datepick.end"
                  :return-value.sync="edit.data.end_date"
                  ref="endDate"
                  offset-y
                  min-width="auto"
                  transition="slide-x-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Selesai"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      v-model="edit.data.end_date"
                    ></v-text-field>
                  </template>
                  <v-date-picker no-title v-model="edit.data.end_date">
                    <v-spacer></v-spacer>
                    <v-btn icon @click="saveDate('endDate')">
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                    <v-btn icon @click="edit.datepick.end = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Batas Peserta"
                  type="number"
                  v-model.number="edit.data.participant_limit"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-autocomplete
                  label="Gratis Ongkir"
                  v-model="edit.data.shipment_cost"
                  :items="allFreeShipment"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Deadline"
                  v-model="edit.data.deadline"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-autocomplete
                  label="Timezone"
                  v-model="edit.data.timezone"
                  :items="timezone"
                  item-text="text"
                  item-value="value"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-file-input
              label="Banner Event"
              v-model="edit.image"
              accept="image/*"
            ></v-file-input>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- delete dialog -->
    <v-dialog v-model="del.show" persistent width="700">
      <v-alert dark class="mb-0" prominent icon="mdi-alert" color="warning">
        <v-row no-gutters class="align-center">
          Apakah anda yakin ingin menghapus data ini?
          <v-spacer></v-spacer>
          <v-btn
            large
            text
            @click="deleteData"
            :loading="del.loading"
            :disabled="del.loading"
          >
            <span>ya</span>
          </v-btn>
          <v-btn large depressed class="ml-2" @click="del.show = false">
            <span>batal</span>
          </v-btn>
        </v-row>
      </v-alert>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" multi-line>
      <v-icon left>{{ snackbar.icon }}</v-icon>
      {{ snackbar.text }}
      <template v-slot:action>
        <v-btn @click="snackbar.show = false" text>
          <span>close</span>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      isActive: [
        { text: "Ya", value: 1 },
        { text: "Tidak", value: 0 },
      ],
      timezone: [
        { text: "UTC+07:00", value: "Asia/Jakarta" },
        { text: "UTC+08:00", value: "Asia/Makassar" },
        { text: "UTC+09:00", value: "Asia/Jayapura" },
      ],
      snackbar: {
        show: false,
        color: null,
        text: null,
        icon: null,
      },
      table: {
        headers: [
          { text: "Status Event", value: "is_active" },
          { text: "Judul", value: "title" },
          { text: "Tanggal Mulai", value: "start_date" },
          { text: "Tanggal Selesai", value: "end_date" },
          { text: "Dealine", value: "deadline" },
          { text: "Timezone", value: "timezone" },
          { text: "Batas Peserta", value: "participant_limit" },
          { text: "Gratis Pengiriman", value: "shipment_cost" },
          { text: "Banner Event", value: "image" },
          { text: "Aksi", value: "action", sortable: false },
        ],
      },
      image: {
        src: null,
        show: false,
      },
      edit: {
        datepick: {
          start: false,
          end: false,
        },
        isEdit: false,
        loading: false,
        data: null,
        image: null,
      },
      del: {
        loading: false,
        show: false,
        id: null,
      },
    };
  },
  methods: {
    ...mapActions(["fetchEvents", "deleteEvent", "updateEvent"]),
    saveDate(ref) {
      switch (ref) {
        case "startDate":
          this.$refs.startDate.save(this.edit.data.start_date);
          break;
        case "endDate":
          this.$refs.endDate.save(this.edit.data.end_date);
          break;
      }
    },
    async showImage(id) {
      console.log(id);
      await axios
        .get(`${process.env.VUE_APP_KB_API_URL}/api/events/image/${id}`)
        .then((response) => {
          this.image.src = response.data;
          console.log(response.data);
          this.image.show = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editData(value) {
      this.edit.data = value;
      this.edit.isEdit = true;
    },
    saveData() {
      this.edit.loading = true;
      let formData = new FormData();
      if (this.edit.image) {
        // append data
        formData.append("title", this.edit.data.title);
        formData.append("start_date", this.edit.data.start_date);
        formData.append("end_date", this.edit.data.end_date);
        formData.append("deadline", this.edit.data.deadline);
        formData.append("timezone", this.edit.data.timezone);
        formData.append("participant_limit", this.edit.data.participant_limit);
        formData.append("shipment_cost", this.edit.data.shipment_cost);
        formData.append("image", this.edit.image);
        // send data
        if (this.updateEvent(formData)) {
          this.showNotification("success");
          this.edit.loading = false;
        } else {
          this.showNotification("failed");
          this.edit.loading = false;
        }
      } else {
        let data = this.edit.data;
        if (this.updateEvent(data)) {
          this.showNotification("success");
          this.edit.loading = false;
        } else {
          this.showNotification("failed");
          this.edit.loading = false;
        }
      }
    },
    deleteConfirmation(id) {
      this.del.id = id;
      this.del.show = true;
    },
    deleteData() {
      let id = this.del.id;
      this.del.loading = true;
      if (this.deleteEvent(id)) {
        this.showNotification("delete");
        this.del.loading = false;
        this.del.show = false;
      } else {
        this.showNotification("delete-failed");
        this.del.loading = false;
      }
    },
    showNotification(status) {
      switch (status) {
        case "success":
          this.snackbar.show = true;
          this.snackbar.color = "success";
          this.snackbar.icon = "mdi-check";
          this.snackbar.text = "Data berhasil diubah";
          break;
        case "failed":
          this.snackbar.show = true;
          this.snackbar.color = "error";
          this.snackbar.icon = "mdi-close";
          this.snackbar.text = "Data gagal diubah";
          break;
        case "delete":
          this.snackbar.show = true;
          this.snackbar.color = "success";
          this.snackbar.icon = "mdi-check";
          this.snackbar.text = "Data berhasil dihapus";
          break;
        case "delete-failed":
          this.snackbar.show = true;
          this.snackbar.color = "error";
          this.snackbar.icon = "mdi-close";
          this.snackbar.text = "Data gagal dihapus";
          break;
        default:
          break;
      }
    },
  },
  computed: {
    ...mapGetters(["allEvents", "allFreeShipment"]),
  },
  mounted() {
    this.fetchEvents();
  },
};
</script>